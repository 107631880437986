import React from 'react';
import { StyleSheet, View, useWindowDimensions } from 'react-native';

import {
  AccountExpirationWarningPortal,
  BottomFixedElementsPortal,
  BottomStickyElementsPortal,
} from 'src/contexts/portals';
import { useOutsideClickDetector } from 'src/hooks';
import { useWebAppLayout } from 'src/hooks/useWebAppLayout';
import { useSideBarContext } from 'src/navigation/contexts/SideBarContext';
import { SIDEBAR_WIDTH } from 'src/styles';

import { SideBar } from './SideBar';
import { Footer } from '../Footer';

interface Props extends React.PropsWithChildren {}

export const SideBarDrawer: React.FC<Props> = ({ children }) => {
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  const { isSideBarOpen, setSideBarOpen, sidebarRef, menuTogglerButtonRef, isSidebarPermanentlyVisible } =
    useSideBarContext();
  const { topPanelHeight, isSidebarAvailable } = useWebAppLayout();

  useOutsideClickDetector([sidebarRef, menuTogglerButtonRef], () => {
    if (!isSidebarPermanentlyVisible) {
      isSideBarOpen && setSideBarOpen(false);
    }
  });

  if (!isSidebarAvailable) {
    return <>{children}</>;
  }

  const rightContentStyles = [
    isSidebarPermanentlyVisible && [
      styles.rightContentWithPermanentSidebar,
      { width: windowWidth - SIDEBAR_WIDTH },
    ],
    !isSidebarPermanentlyVisible && isSideBarOpen && styles.rightContentWithOpenSidebar,
  ];

  return (
    <>
      <View
        ref={sidebarRef}
        style={[
          styles.sideBar,
          isSideBarOpen && styles.sideBarOpen,
          { height: windowHeight - topPanelHeight },
        ]}
      >
        <SideBar />
      </View>
      <View style={[styles.content, rightContentStyles, { minHeight: windowHeight - topPanelHeight }]}>
        {children}
        <View style={styles.contentFooterSpacer} />
        <Footer />
        <View style={[styles.stickyElements]}>
          <BottomStickyElementsPortal.Outlet />
        </View>
      </View>
      <View style={[styles.bottomElements, rightContentStyles]}>
        <BottomFixedElementsPortal.Outlet />
        <AccountExpirationWarningPortal.Outlet />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  sideBar: {
    width: '100%',
    maxWidth: SIDEBAR_WIDTH,
    zIndex: 2,
    position: 'fixed',
    transform: [{ translateX: -SIDEBAR_WIDTH }],
    transitionProperty: 'transform',
  },
  sideBarOpen: {
    transform: [{ translateX: 0 }],
  },
  content: {
    width: '100%',
    transitionProperty: 'transform',
    justifyContent: 'space-between',
  },
  contentFooterSpacer: {
    flex: 1,
  },
  rightContentWithOpenSidebar: {
    transform: [{ translateX: SIDEBAR_WIDTH }],
  },
  rightContentWithPermanentSidebar: {
    marginLeft: SIDEBAR_WIDTH,
  },
  bottomElements: {
    position: 'fixed',
    bottom: 0,
    zIndex: 1,
    width: '100%',
    pointerEvents: 'box-none',
    transitionProperty: 'transform',
  },
  stickyElements: {
    position: 'sticky',
    bottom: 0,
    zIndex: 1,
    width: '100%',
  },
});
